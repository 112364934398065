.video-item img.ui.image {
  /* width: 50px; */
  max-width: 150px;
}

.video-item {
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
}
